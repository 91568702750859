@import '~styles/sass-includes';

.listing-order-form .AfterpayMessage {
  margin: 0px auto !important;

}
.listing-header__wrapper {
  // media queries
  @include breakpoint($mobile-lg) {
    display: flex;
  }
}

.listing-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // media queries
  @include breakpoint($mobile-lg) {
    align-items: center;
    width: 100%;
  }

  h4 {
    padding-right: 10px;
  }

  > div {
    // media queries
    @include breakpoint($mobile-lg) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
.select .varient_selector__mobile {
  min-width: 100%;
}
.listing-header__price span {
  font-size: 18px;
  margin-top: 0px;
  @include breakpoint($mobile-lg){
    font-size: 18px;
  }
}
.listing__cta {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;

  > span {
    position: absolute;
  }

  .btn {
    flex: 1;
  }
}

.variant-label-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  // media queries
  @include breakpoint($mobile-lg) {
    flex-wrap: nowrap;
    justify-content: space-between;

    .pr_selectfield__wrapper {
      width: 100%;
      margin-bottom: 0;
    }

  }
}

.color-label {
  flex: 3;
  min-width: calc(75%);
  margin-left: 4px;
  white-space: nowrap;
  font-size: 1.5rem;
  margin-bottom: 12px;
  @include breakpoint($tablet-lg) {
    display: none;
  }
}

.listing-details-container {
  @include breakpoint($mobile-lg){
    width: 100vw;
    padding: 0 16px;
  }
}

.listing-details {
  font-family: 'Montserrat' !important;
  position: sticky;
  top: 3.1rem;
  margin-top: -6.1rem;
  padding-top: 6.1rem;
  border: 1px solid $neutral20;
  padding-left: 30px;
  padding-right: 30px;
  // media queries
  @include breakpoint($desktop-sm) {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  @include breakpoint($mobile-lg) {
    width: 100%;
    top: 12px;
  }
  .listing-header__price .price {
    color: var(--neutral-100);
    // media queries
    @include breakpoint($mobile-lg) {
      font-size: 18px;
    }
  }

  .listing-header__title {
    font-size: 2.8rem;
    font-weight: 500;
    color: var(--neutral-100);
    // media queries
    @include breakpoint($mobile-lg) {
      font-size: 14px;
      font-weight: 400;
      color: var(--neutral-100);
    }
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--neutral-70);
    // media queries
    @include breakpoint($mobile-lg) {
      font-size: 12px;
    }
  }

  .form__label {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .qtyfield {
    display: none;
  }

  .color .variantselector__item {
    margin: 5px;
  }

  .pr_tabs__item {
    padding-right: 5px;
  }
}
.listing-details__placeholder {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: transparent;
}

.listing-details .pr_accordion .pr_accordion__title {
  font-size: 14px;
  font-weight: 500;
}

.listing__holidaymessage {
  background-color: #EEE;
  padding: 7px 15px;
  color: $black;
  border-radius: 3px;
}

.listing__addcart--mobile {
  position: fixed;
  display: flex;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 20;

  > .pr_btn {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .pr_btn {
    height: 60px;
    margin-top: 15px;
    border-radius: 0;
  }

  .stickymenu .pr_btn {
    width: calc(100% + 50px);
    margin-left: -25px;
    justify-content: center;
  }

  .stickymenu {
    width: 100%;
    background-color: $white;
    padding: 25px 25px 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    z-index: 5;
    position: relative;
  }

  .stickymenu__overlay {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.5);
    z-index: -1;
  }
}

.listing__options {
  &.sizes {
    .pr_selectfield{
      &.varient_selector__mobile {
        color: $black;
      }
    }
  }

  &.colors {
    .pr_selectfield{
      &.varient_selector__mobile {
        color: $black !important;
      }
    }
  }

  .pr_inputfield__label--animated {
    z-index: 10;
  }

  position: relative;
  z-index: 15;
}
