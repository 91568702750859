@import '~styles/_sass-includes.scss';


.header__feature {
    border: solid 1px $swtheoryBlue;
    border-radius: 12px;
    padding: 8px;
    background: rgba(14, 96, 255, 0.10);
    width: 60%;
    display: flex;
    gap: 8px;
    min-width: 240px;
    
    @include breakpoint($tablet-lg) {
        display: none;
    }
}

.header__feature-title {
    font-size: 1.4rem !important;
    font-weight: 400 !important;
    color: #84888F !important;
}

.header__feature-end-date {
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #84888F;
}

.header__feature-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.header__feature-button {
    background: rgba(255, 255, 255, 0.11);
    border-radius: 7px;
    padding: 8px 12px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Acumin', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 8px;
}

.header__feature-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.digits {
    color: #FFF;
    font-weight: 700;
}

@media screen and (max-width: 1600px){
    .button-label {
        display: none;
    }
}

@media screen and (max-width: 1200px){
    .header__feature {
        display: none;
    }
}