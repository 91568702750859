@import 'styles/_sass-includes.scss';

.timer__feature-content {
    border-radius: 9px;
    border: 2px solid #F2F2F2;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05) inset;
    padding: 24px;

    h4 {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .timer__feature-details {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .digits {
        gap: 16px;
        .digit {
            display: flex;
            flex-direction: column;
            align-items: start;
            .label {
                font-family: Inter;
                font-size: 14px;
                color: black;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1.259px;
                text-transform: uppercase;
            }
        }
        display: flex;
        flex-direction: row;
        font-family: 'Acumin', sans-serif;
        color: $swtheoryBlue;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 4px;
    }
}